import PropTypes from "prop-types";
import styles from './text.module.scss';

const renderTag = {
  h1: (
    textAlign,
    fontWeight,
    textTransform,
    className,
    color,
    style,
    children
  ) => (
    <h1
      className={className}
      style={{ textAlign, fontWeight, textTransform, color, ...style }}
    >
      {children}
    </h1>
  ),
  h2: (
    textAlign,
    fontWeight,
    textTransform,
    className,
    color,
    style,
    children,
    ellipsis
  ) => (
    <h2
      className={`${className} ${ellipsis ? styles.ellipsis : ''}`}
      style={{ textAlign, fontWeight, textTransform, color, ...style }}
    >
      {children}
    </h2>
  ),
  subHeader: (
    textAlign,
    fontWeight,
    textTransform,
    className,
    color,
    style,
    children
  ) => (
    <div
      className={className}
      style={{ textAlign, fontWeight, textTransform, color, ...style }}
    >
      {children}
    </div>
  ),
  label: (
    textAlign,
    fontWeight,
    textTransform,
    className,
    color,
    style,
    children
  ) => (
    <label
      className={className}
      style={{ textAlign, fontWeight, textTransform, color, ...style }}
    >
      {children}
    </label>
  ),
  link: (
    textAlign,
    fontWeight,
    textTransform,
    className,
    color,
    style,
    children
  ) => (
    <span
      className={className}
      style={{ textAlign, fontWeight, textTransform, color, ...style }}
    >
      {children}
    </span>
  ),
  linkBigOnDesktop: (
    textAlign,
    fontWeight,
    textTransform,
    className,
    color,
    style,
    children
  ) => (
    <span
      className={className}
      style={{ textAlign, fontWeight, textTransform, color, ...style }}
    >
      {children}
    </span>
  ),
  body: (
    textAlign,
    fontWeight,
    textTransform,
    className,
    color,
    style,
    children
  ) => (
    <p
      className={className}
      style={{ textAlign, fontWeight, textTransform, color, ...style }}
    >
      {children}
    </p>
  ),
  button: (
    textAlign,
    fontWeight,
    textTransform,
    className,
    color,
    style,
    children
  ) => (
    <label
      className={className}
      style={{ textAlign, fontWeight, textTransform, color, ...style }}
    >
      {children}
    </label>
  ),
};

const classes = {
  h1: "h1",
  h2: "h2",
  subHeader: "subHeader",
  label: "label",
  link: "link",
  linkBigOnDesktop: "linkBigOnDesktop",
  body: "body",
  button: "button",
};

const Text = ({
  tag = "body",
  variant = tag,
  align, // TODO: remove this option, it's a security risk (unsafe inline styles)!
  weight, // TODO: remove this option, it's a security risk (unsafe inline styles)!
  transform, // TODO: remove this option, it's a security risk (unsafe inline styles)!
  color, // TODO: remove this option, it's a security risk (unsafe inline styles)!
  style, // TODO: remove this option, it's a security risk (unsafe inline styles)!
  children,
  ellipsis
}) => {
  return renderTag[tag](
    align,
    weight,
    transform,
    classes[variant],
    color,
    style,
    children,
    ellipsis
  );
};

Text.propTypes = {
  tag: PropTypes.oneOf([
    "h1",
    "h2",
    "subHeader",
    "label",
    "link",
    "linkBigOnDesktop",
    "body",
    "button",
  ]),
  variant: PropTypes.oneOf([
    "h1",
    "h2",
    "subHeader",
    "label",
    "link",
    "linkBigOnDesktop",
    "body",
    "button",
  ]),
  align: PropTypes.oneOf([
    "left",
    "right",
    "center",
    "start",
    "end",
    "justify",
    "inherit",
    "initial",
    "unset",
  ]),
  transform: PropTypes.oneOf([
    "none",
    "capitalize",
    "lowercase",
    "uppercase",
    "inherit",
    "initial",
    "unset",
  ]),
  style: PropTypes.object,
  color: PropTypes.string,
  children: PropTypes.node,
};

export default Text;
