// import NextImage from "next/image";
// import loader from "utils/image-loader";
import styles from "./image.module.scss";

const Image = ({ image, ...props }) => {

  const mapImagesToResponseivePicture = (image) => {
    const className =
        (props.className ? props.className + " " : "") +
        (!image.mobile ? styles.desktop : "");
    return (
        <picture>
            {!(!!props.disableResize) && <source media="(max-width: 768px)" alt={image.alt} srcSet={image.mobile?.formats?.medium?.url}
                     className={className}/>}
            {!(!!props.disableResize) && <source media="(min-width: 769px)" alt={image.alt} srcSet={image.desktop?.url} className={className}/>}
          <img width={props?.width} height={props?.height} src={image.desktop?.url}
               className={`${className} ${!!props.rounded ? styles.rounded: ''}`}  alt={image.alt}  />
        </picture>
    )
  }

  if (image) {
    return mapImagesToResponseivePicture(image);

  } else {
    return <img {...props} />;
  }
};

export default Image;
