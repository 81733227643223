import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function useUtmSessionStorage() {
	const router = useRouter();

	useEffect(() => {
		if (!sessionStorage || !('URLSearchParams' in window)) {
			return;
		}

		const searchParams = new URLSearchParams(window.location.search);

		const utmSource = searchParams.get('utm_source');
		const utmMedium = searchParams.get('utm_medium');
		const utmContent = searchParams.get('utm_content');
		const utmCampaign = searchParams.get('utm_campaign');
		const utmTerm = searchParams.get('utm_term');

		if (utmSource) {
			sessionStorage.setItem('utm_source', utmSource);
			searchParams.delete('utm_source');
		}
		if (utmMedium) {
			sessionStorage.setItem('utm_medium', utmMedium);
			searchParams.delete('utm_medium');
		}
		if (utmContent) {
			sessionStorage.setItem('utm_content', utmContent);
			searchParams.delete('utm_content');
		}
		if (utmCampaign) {
			sessionStorage.setItem('utm_campaign', utmCampaign);
			searchParams.delete('utm_campaign');
		}
		if (utmTerm) {
			sessionStorage.setItem('utm_term', utmTerm);
			searchParams.delete('utm_term');
		}

		if (utmSource || utmMedium || utmContent || utmCampaign || utmTerm) {
			router.push(
				router.asPath.split('?')[0] + searchParams.toString() !== ''
					? '?' + searchParams.toString()
					: '',
			);
		}
	}, [router]);
}
