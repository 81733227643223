import { useEffect, useState } from "react";

export default function useScroll() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const listener = () => {
      setIsScrolled(window.scrollY > 0);
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", listener);
    listener();

    return () => window.removeEventListener("scroll", listener);
  }, []);

  return { isScrolled, scrollY };
}
