import React from "react";
import PropTypes from "prop-types";
import styles from "./button.module.scss";
import Text from "../text";
import Image from "../image";

const Button = ({ text, onClick, type, icon, iconWidth, iconHeight, minimumWidth }) => {
  if (type === "icon") {
    if (!iconWidth && !iconHeight) {
      iconWidth = iconHeight = 24;
    }
    return (
      <button className={`${styles.btn} ${styles.icon} ${!!minimumWidth ? styles.minimumWidth : ''}`} onClick={onClick}>
        <Image src={icon} width={iconWidth} height={iconHeight} alt={text} />
      </button>
    );
  }

  if (type === "image") {
    if (!iconWidth && !iconHeight) {
      iconWidth = iconHeight = 24;
    }
    return (
      <button className={styles.btn + " " + styles.image} onClick={onClick}>
        <Image src={icon} width={iconWidth} height={iconHeight} alt={text} />
      </button>
    );
  }

  if (type == "primary") {
    return (
      <button className={`${styles.btnWithShadow} ${!!minimumWidth ? styles.minimumWidth : ''}`} onClick={onClick}>
        <div className={styles.background}>
          <Text tag="body" variant="button">
            {text}
          </Text>
        </div>
        <div className={styles.shadow + " " + styles.primary}>&nbsp;</div>
      </button>
    );
  }

  return (
    <button className={`${styles.btn} ${styles[type]} ${!!minimumWidth ? styles.minimumWidth : ''}`} onClick={onClick}>
      <Text tag="body" variant="button">
        {text}
      </Text>
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["primary", "secondary", "outlined", "icon", "image"]),
  icon: PropTypes.string,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
};

Button.defaultProps = {
  type: "primary",
};

export default Button;
