import { useEffect, useState } from "react";

export default function useDeviceDetect(smallScreenThreshold) {
	// one state to reduce multiple re-renders when resizing window
	const [state, setState] = useState({
		screenWidth: 0,
		screenHeight: 0,
		isSmallScreen: true,
	});

	smallScreenThreshold = smallScreenThreshold ? smallScreenThreshold : 768;

	useEffect(() => {
		const handleResize = () => {
			setState({
				screenWidth: window.innerWidth,
				screenHeight: window.innerHeight,
				isSmallScreen: window.innerWidth < smallScreenThreshold,
			});
		};

		window.addEventListener("resize", handleResize);
		handleResize();

		return () => window.removeEventListener("resize", handleResize);
	}, [smallScreenThreshold]);

	return state;
}
