import { useEffect } from 'react';
import * as gtm from '../lib/gtm';
import logger from '../lib/logger';
import appConfig from '../config.json';

const ignoredEventOrigins = [
	'https://tpc.googlesyndication.com', // Referrals from Google Ads
	'https://consentcdn.cookiebot.com', // Cookie consent (cookiebot)
	'https://js.klarna.com',
	'https://js.playground.klarna.com',
	'https://tr.snapchat.com',
];

export default function useGlobalMessageHandler({ originWhitelist }) {
	const messageHandler = (event) => {
		// IMPORTANT: Check the origin of the data!
		if (
			event.origin === location.origin ||
			event.origin.indexOf(appConfig.wizard) >= 0 ||
			originWhitelist?.includes(event.origin)
		) {
			switch (event.data.id) {
				case 'checkup-started': {
					gtm.checkupStarted(event.data.data);
					return;
				}
				case 'question-shown': {
					gtm.checkupQuestionShown(event.data.data);
					return;
				}
				case 'question-answered': {
					gtm.checkupQuestionAnswered(event.data.data);
					return;
				}
				case 'checkup-complete': {
					gtm.checkupCompleted(event.data.data);
					return;
				}
				case 'checkup-failed-not-eligble': {
					gtm.checkupNotEligible(event.data.data);
					return;
				}
				case 'outbound-partner-doktor.se': {
					gtm.redirectToDoktorSe(event.data.data);
					return;
				}
				case 'checkup-success': {
					gtm.checkupSuccess(event.data.data);
					return;
				}
				case 'contact-info-started': {
					gtm.identificationStarted(event.data.data);
					return;
				}
				case 'contact-info-email-added': {
					gtm.identificationEmail(event.data.data);
					return;
				}
				case 'contact-info-bankid-started': {
					gtm.identificationBankIdStarted(event.data.data);
					return;
				}
				case 'contact-info-bankid-success': {
					gtm.identificationBankIdSuccess(event.data.data);
					return;
				}
				case 'contact-info-bankid-failure': {
					gtm.identificationBankIdFailed(event.data.data);
					return;
				}
				case 'payment-started': {
					gtm.paymentStarted(event.data.data);
					return;
				}
				case 'payment-success': {
					gtm.paymentComplete(event.data.data);
					return;
				}
				case 'payment-failure': {
					gtm.paymentFail(event.data.data);
					return;
				}
			}
		} else if (!ignoredEventOrigins.find((o) => event.origin.indexOf(o) == 0)) {
			if (event.data.type) return;
			// Log unknown events
			logger.warning(
				'Unknown event origin for window message: ' +
					JSON.stringify(event) +
					'. Origin "' +
					event.origin +
					'"' +
					' should have been "' +
					appConfig.wizard +
					'"',
			);
		}
	};

	useEffect(() => {
		window.addEventListener('message', messageHandler);
		return () => void window.removeEventListener('message', messageHandler);
	});
}
