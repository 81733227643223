import * as Sentry from "@sentry/nextjs";

const logger = {
  info(message) {
    Sentry.captureMessage(message, Sentry.Severity.Info);
  },
  warning(message) {
    Sentry.captureMessage(message, Sentry.Severity.Warning);
  },
  error(error) {
    Sentry.captureException(error);
  },
  breadcrumb(message, category) {
    Sentry.addBreadcrumb({
      category: category || "unknown",
      message,
      level: Sentry.Severity.Info,
    });
  },
};

export default logger;
