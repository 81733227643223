/**
 * Pushes event to GTM through window.dataLayer
 * @param  {} event
 */
const push = (event) => {
	if (window.dataLayer) {
		window.dataLayer.push(event);
	}
};

/**
 * transforms revisit to "new" or "revisit" dependent on boolean
 * @param  {boolean} revisit
 */
const humanReadableRevisit = (revisit) => {
	return revisit ? 'revisit' : 'new';
};

/**
 * Shortens the diagnosis type to a two letter code.
 * @param  {string} diagnosis
 */
const shortenDiagnosisName = (diagnosis) => {
	switch (diagnosis) {
		case 'urinkollen':
		case 'urinkollen1': {
			return 'UK';
		}
		case 'akne':
		case 'akne_revisit':
		case 'akne1':
		case 'akne_revisit1': {
			return 'AK';
		}
		case 'borrelia':
		case 'borrelia1': {
			return 'BK';
		}
		case 'herpes_revisit1':
		case 'herpes_revisit': {
			return 'HK';
		}
		case 'mens':
		case 'mens_revisit':
		case 'mens1':
		case 'mens_revisit1': {
			return 'MK';
		}
		case 'ogon1':
		case 'ogon':
		case 'ogon_revisit':
		case 'ogon_revisit1': {
			return 'OK';
		}
		case 'potens':
		case 'potens1':
		case 'potens_revisit':
		case 'potens_revisit1': {
			return 'POK';
		}
		case 'pollen1':
		case 'pollen':
		case 'pollen_revisit':
		case 'pollen_revisit1': {
			return 'PK';
		}
		case 'ppiller_revisit':
		case 'ppiller_revisit1':
			return 'PPK';
		default:
			return diagnosis === undefined ? null : diagnosis;
	}
};

export const ctaAllEvents = (location) => {
	push({
		event: 'cta',
		location: location,
		check: 'all',
	});
};

export const pageEvent = (page) => {
	let addedSlash;
	if (!page) {
		addedSlash = '/';
	} else {
		addedSlash = '/' + page;
	}
	push({
		event: 'virtualPageView',
		page: addedSlash,
	});
};

export const ctaEvents = (location, diagnosisType, type) => {
	push({
		event: 'cta',
		location: location,
		check: shortenDiagnosisName(diagnosisType),
		type: humanReadableRevisit(type),
	});
};

export const checkupStarted = (data) => {
	const event = {
		event: 'checkup',
		step: 'triage-started',
		check: shortenDiagnosisName(data.diagnosis),
		action: 'none',
		group: 'triage',
		type: humanReadableRevisit(data.revisit),
	};
	push(event);
};

export const checkupQuestionShown = (data) => {
	const event = {
		event: 'checkup',
		step: 'Q' + data.step,
		check: shortenDiagnosisName(data.diagnosis),
		action: 'shown',
		group: 'triage',
		type: humanReadableRevisit(data.revisit),
	};
	push(event);
};

export const checkupSuccess = (data) => {
	const event = {
		event: 'checkup',
		group: null,
		step: 'success',
		check: shortenDiagnosisName(data.diagnosis),
		action: 'end',
		type: humanReadableRevisit(data.revisit),
		payment_provider: null,
	};
	push(event);
};

export const checkupQuestionAnswered = (data) => {
	const event = {
		event: 'checkup',
		step: 'Q' + data.step,
		check: shortenDiagnosisName(data.diagnosis),
		action: 'answered',
		group: 'triage',
		type: humanReadableRevisit(data.revisit),
	};
	push(event);
};

export const redirectToDoktorSe = (data) => {
	const event = {
		event: 'checkup',
		step: 'Q' + data.step,
		check: shortenDiagnosisName(data.diagnosis),
		action: 'outbound-click',
		group: 'triage',
		type: humanReadableRevisit(data.revisit),
	};
	push(event);
};

export const checkupNotEligible = (data) => {
	const event = {
		event: 'checkup',
		step: 'Q' + data.step,
		check: shortenDiagnosisName(data.diagnosis),
		action: 'kicked-out',
		is_referred: data.referred,
		group: 'triage',
		type: humanReadableRevisit(data.revisit),
	};
	push(event);
};

export const checkupCompleted = (data) => {
	const event = {
		event: 'checkup',
		step: 'triage-complete',
		check: shortenDiagnosisName(data.diagnosis),
		group: 'triage',
		action: 'success',
		type: humanReadableRevisit(data.revisit),
	};
	push(event);
};

export const identificationStarted = (data) => {
	const event = {
		event: 'checkup',
		step: 'contact',
		check: shortenDiagnosisName(data.diagnosis),
		action: 'start',
		group: 'identification',
		type: humanReadableRevisit(data.revisit),
	};
	push(event);
};

export const identificationEmail = (data) => {
	const event = {
		event: 'checkup',
		step: 'contact',
		check: shortenDiagnosisName(data.diagnosis),
		action: 'email-added',
		group: 'identification',
		uid: data.id,
		type: humanReadableRevisit(data.revisit),
	};
	push(event);
};

export const identificationBankIdStarted = (data) => {
	const event = {
		event: 'checkup',
		step: 'bank-id',
		check: shortenDiagnosisName(data.diagnosis),
		action: 'start',
		group: 'identification',
	};
	push(event);
};

export const identificationBankIdFailed = (data) => {
	const event = {
		event: 'checkup',
		step: 'bank-id',
		check: shortenDiagnosisName(data.diagnosis),
		action: 'fail',
		group: 'identification',
	};
	push(event);
};

export const identificationBankIdSuccess = (data) => {
	const event = {
		event: 'checkup',
		step: 'bank-id',
		check: shortenDiagnosisName(data.diagnosis),
		action: 'success',
		group: 'identification',
		opts: data.opts,
	};
	push(event);
};

export const paymentStarted = (data) => {
	const event = {
		event: 'checkup',
		step: 'payment',
		check: shortenDiagnosisName(data.diagnosis),
		action: 'start',
		group: 'payment',
		payment_provider: data.provider === undefined ? null : data.provider,
		type: humanReadableRevisit(data.revisit),
	};
	push(event);
};

export const paymentComplete = (data) => {
	const event = {
		event: 'checkup',
		step: 'payment',
		check: shortenDiagnosisName(data.diagnosis),
		action: 'success',
		group: 'payment',
		payment_provider: data.provider === undefined ? null : data.provider,
		type: humanReadableRevisit(data.revisit),
	};
	push(event);
};

export const paymentFail = (data) => {
	const event = {
		event: 'checkup',
		step: 'payment',
		check: shortenDiagnosisName(data.diagnosis),
		action: 'fail',
		group: 'payment',
		payment_provider: data.provider === undefined ? null : data.provider,
		type: humanReadableRevisit(data.revisit),
	};
	push(event);
};
